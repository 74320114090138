import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import AscaLogo from "../images/asca-logo.png"
import TwintLogo from "../images/Twint.svg"
import Hero from "../components/hero"
import PricingTable from "../components/pricingtable"
import Image from "../components/tacticalImg"
import Cta from "../components/cta"
import CtaBtn from "../components/ctabtn"
import SEO from "../components/seo"

const Card = styled.div`
  box-shadow: var(--box-shadow);
  padding: var(--xl);
  text-align: center;
  img {
    margin-top: var(--medium);
  }
  margin: var(--medium) 0;
`

const CtaSection = styled.section`
  background: var(--soft);
  font-family: "Prata", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  padding: var(--xl) 0;
  font-size: 2.25rem;
  line-height: 3rem;
  text-align: center;
  small {
    font-size: 1.5rem;
  }
`

const Prices = ({ setShowCta, cta }) => (
  <Layout>
    <SEO title="Tarifs" />
    <Hero title="Tarifs" hero="tarifs-hero.jpg" />
    <PricingTable />
    <div className="container lead highlow">
      <h2>Prix Des Massages Remboursé</h2>
      <p>
        En tant que thérapeute agréée ASCA, mes prestations peuvent être prises
        en charge par certaines assurances complémentaires.
      </p>
      <p>
        Prestations remboursées par les caisses d’assurance complémentaires qui
        reconnaissent les associations ASCA
      </p>
      <p>
        Après l’entrée en vigueur de la facturation <strong>590</strong> et
        l’obligation d’appliquer un code tarifaire unique , toutes prestations
        remboursées seront facturées comme suit :
      </p>
      <p>Méthodes reconnues : massage classique, massage thérapeutique.</p>
      <p>
        Chaque client doit préalablement s’informer auprès de son assureur si la
        thérapie et le thérapeute choisis sont remboursés par son assurance
        complémentaire.
      </p>
      <p>
        Je ne pourrai en aucun cas être tenue responsable des décisions de
        remboursement des prestations facturées.
      </p>
      <div style={{ textAlign: "center", paddingTop: "var(--large)" }}>
        <img src={AscaLogo} alt="Asca Logo" />
      </div>
    </div>
    <Cta setShowCta={setShowCta} cta={cta} />
    <div className="container lead" style={{ padding: "var(--xl2) 0" }}>
      <h2>Abonnements</h2>
      <div className="grid">
        <Card className="col-md-6 side-padding">
          <h3>Avantage</h3>
          <p>
            Bénéficiez d’un{" "}
            <strong>
              rabais de 10% et profitez de massages à prix avantageux
            </strong>{" "}
            (non valable pour les massages remboursés par l’assurance)!
          </p>
        </Card>
        <Card className="col-md-6 side-padding">
          <h3>Flexibilité</h3>
          <p>
            <strong>Vous voulez partager un abonnement avec votre ami?</strong>
            <br />
            Vous êtes les bienvenus.
          </p>
          <p>
            <strong>
              Vous avez un empêchement et vous ne pouvez plus venir?
            </strong>
            <br />
            Ne vous inquiétez pas, votre abonnement est transmissible à l’un de
            vos proches.
          </p>
          <p>
            <strong>
              Les abonnements sont valables 12 mois à partir de la date du 1er
              rendez-vous.
            </strong>
          </p>
        </Card>
      </div>
      <Card className="side-padding">
        <h3>Vous voulez annuler un rendez-vous?</h3>
        <p>
          <strong>
            Annulation d’un rendez-vous plus de 24 heures à l’avance
          </strong>
          <br />
          Aucun frais d’annulation ne vous sera facturé.
          <br />
          <strong>
            Annulation d’un rendez-vous moins de 24 heures à l’avance
          </strong>
          <br />
          Le montant complet du soin sera facturé.
        </p>
        <p
          style={{
            color: "var(--heading)",
            fontSize: "2rem",
          }}
        >
          Merci!
        </p>
        <p>Cet espace n’est pas équipé pour le paiement par carte de crédit.</p>
        <p>
          Les séances de beauté et massages se règlent en espèces.
          <br />
          Le règlement est aussi possible par.
        </p>
        <img src={TwintLogo} alt="Twint logo" width="130" />
      </Card>
    </div>
    <CtaSection>
      <div className="container grid">
        <div className="col-md-1"></div>
        <div className="col-md-10 side-padding">
          <p>Forfait Beauté pour tous</p>
          <p>
            <small>
              Bénéficier de 20% de remise sur une sélection de soins esthétiques
            </small>
          </p>
          <CtaBtn setShowCta={setShowCta} cta={cta} />
        </div>
        <div className="col-md-1"></div>
      </div>
    </CtaSection>
    <div className="container">
      <div style={{ margin: "var(--xl2) 0 var(--xl)" }}>
        <Image src="happy-girls.jpg" alt="Forfait Étudiants" />
      </div>
      <div
        style={{
          margin: "0 auto",
          textAlign: "center",
          paddingBottom: "var(--xl2)",
          maxWidth: "60ch",
        }}
        className="side-padding"
      >
        <h3>Forfait Étudiants</h3>
        <p>
          Soins esthétiques à petits prix pour les moins de 20 ans (soin visage,
          épilations...)
        </p>
        <p>
          Je propose aux adolescents (filles et garçons) bénéficier de{" "}
          <strong>25% de remise</strong> sur une sélection de{" "}
          <strong>soins esthétiques</strong>
        </p>
        <ul className="services">
          <li>Soin du visage nettoyant et purifiant</li>
          <li>Épilation du visage et du corps</li>
          <li>Beauté des mains</li>
          <li>Beauté des pieds</li>
        </ul>
        <p style={{ paddingTop: "var(--medium)" }}>
          Soin du visage, soin du visage pour les peaux jeunes à problèmes,
          boutons et points noirs
        </p>
        <p>
          <strong>À partir de:</strong> CHF 50.-
          <br />
          <strong>Durée</strong>: 30 min à 1H15 min
        </p>
        <p>
          Lors de votre rendez-vous veuillez préciser votre âge pour bénéficier
          des tarifs réduits, une carte d'identité vous sera demandée.
        </p>
        <p>Pour plus d'informations ou pour prendre rendez-vous</p>
      </div>
    </div>
  </Layout>
)

export default Prices
