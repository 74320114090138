import React from "react"
import styled from "styled-components"

const Pricing = styled.div`
  background: #f6f2f3;
  padding: var(--xl2) 0 var(--xl2);
  h3 {
    text-align: center;
    padding: var(--xl2) 0 var(--large);
    @media (max-width: 640px) {
      padding: 6rem 0 var(--large);
    }
  }
  h3:first-child {
    padding-top: 0;
  }
  table {
    margin: 0 auto;
    width: 585px;
    @media (max-width: 640px) {
      width: 300px;
      max-width: 100%;
    }
  }
  th {
    text-transform: uppercase;
    text-align: left;
    padding-bottom: var(--small);
  }
  .price,
  .duration,
  .price-description {
    text-align: center;
    width: 6rem;
  }
  .price-description {
    font-weight: normal;
  }
  .price {
    font-weight: 700;
  }
  hr {
    text-align: center;
    margin: var(--large) auto;
    width: 370px;
    max-width: 100%;
    border: 1px solid #cbcbcb;
  }
  .upper {
    text-transform: uppercase;
  }
  p {
    margin: var(--medium) auto;
    max-width: 75ch;
    text-align: center;
  }
`

const pricingtable = () => {
  return (
    <Pricing>
      <div className="container side-padding">
        <h3>Prix des Massages</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className="price-description">Durée</th>
              <th className="price-description">CHF</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Massage Classique</th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <td className="upper">Du dos</td>
              <td className="duration">30min</td>
              <td className="price">60.-</td>
            </tr>
            <tr>
              <td className="upper">Corps Complet</td>
              <td className="duration">60min</td>
              <td className="price">120.-</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Massage thérapeutique</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="upper"></td>
              <td className="duration">30min</td>
              <td className="price">60.-</td>
            </tr>
            <tr>
              <td className="upper"></td>
              <td className="duration">60min</td>
              <td className="price">120.-</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Massage Assis</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td className="duration">15min</td>
              <td className="price">25.-</td>
            </tr>
            <tr>
              <td></td>
              <td className="duration">30min</td>
              <td className="price">45.-</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Massage du dos aux Huiles Essentielles</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td className="duration">30min</td>
              <td className="price">50.-</td>
            </tr>
            <tr>
              <td></td>
              <td className="duration">60min</td>
              <td className="price">100.-</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Massage du dos aux Pierres Chaudes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td className="duration">45min</td>
              <td className="price">80.-</td>
            </tr>
            <tr>
              <td></td>
              <td className="duration">80min</td>
              <td className="price">140.-</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Massage à la bougie</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="upper"></td>
              <td className="duration">30min</td>
              <td className="price">50.-</td>
            </tr>
            <tr>
              <td className="upper"></td>
              <td className="duration">60min</td>
              <td className="price">100.-</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Massage amincissant et anti-cellulite</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="upper"></td>
              <td className="duration">30min</td>
              <td className="price">50.-</td>
            </tr>
            <tr>
              <td className="upper"></td>
              <td className="duration">60min</td>
              <td className="price">100.-</td>
            </tr>
          </tbody>
        </table>
        <h3>Prix des Soins</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className="price-description">Durée</th>
              <th className="price-description">CHF</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Visage</th>
            </tr>
            <tr>
              <td className="upper">Nettoyage de la peau flash</td>
              <td className="duration">30min</td>
              <td className="price">45.-</td>
            </tr>
            <tr>
              <td className="upper">Nettoyage de la peau profond</td>
              <td className="duration">50min</td>
              <td className="price">65.-</td>
            </tr>
            <tr>
              <td className="upper">Nettoyage de la peau + Soin</td>
              <td className="duration">70min</td>
              <td className="price">95.-</td>
            </tr>
            <tr>
              <td className="upper">
                Nettoyage de la peau + Soin hydratant + massage
              </td>
              <td className="duration">90min</td>
              <td className="price">120.-</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Corps</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="upper">Gommage du dos</td>
              <td className="price">60.-</td>
            </tr>
            <tr>
              <td className="upper">
                <small>Soins du corps *Prix abonnement sur demande.</small>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>Prix Épilation</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className="price-description">CHF</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Épilation à la Cire</th>
            </tr>
            <tr>
              <td className="upper">Visage</td>
              <td className="price">15.- à 25.-</td>
            </tr>
            <tr>
              <td className="upper">Sourcils</td>
              <td className="price">15.- à 30.-</td>
            </tr>
            <tr>
              <td className="upper">Bikini</td>
              <td className="price">20.- à 50.-</td>
            </tr>
            <tr>
              <td className="upper">Demi-Jambes</td>
              <td className="price">20.- à 55.-</td>
            </tr>
            <tr>
              <td className="upper">Jambes Complétés</td>
              <td className="price">55.- à 85.-</td>
            </tr>
          </tbody>
        </table>
        <h3>Forfaits Épilations</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className="price-description">CHF</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="upper">Bikini simple + aisselles</td>
              <td className="price">25.-</td>
            </tr>
            <tr>
              <td className="upper">Bikini brésilien + aisselles</td>
              <td className="price">30.-</td>
            </tr>
            <tr>
              <td className="upper">
                Demi-jambes complètes + aisselles + bikini Brésilien
              </td>
              <td className="price">50.-</td>
            </tr>
            <tr>
              <td className="upper">Jambes complètes + bikini simple</td>
              <td className="price">75.-</td>
            </tr>
            <tr>
              <td className="upper">Jambes complètes + bikini brésilien</td>
              <td className="price">80.-</td>
            </tr>
            <tr>
              <td className="upper">Jambes complètes + bikini intégral</td>
              <td className="price">100.-</td>
            </tr>
            <tr>
              <td className="upper">
                Jambes complètes + bikini Brésilien + aisselles
              </td>
              <td className="price">120.-</td>
            </tr>
          </tbody>
        </table>
        <h3>Mains & Pieds</h3>
        <table>
          <thead>
            <tr>
              <th></th>
              <th className="price-description">CHF</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Manucure</th>
            </tr>
            <tr>
              <td className="upper">Pose Vernis</td>
              <td className="price">15.-</td>
            </tr>
            <tr>
              <td className="upper">Manucure</td>
              <td className="price">40.-</td>
            </tr>
            <tr>
              <td className="upper">Manucure + Vernis</td>
              <td className="price">50.-</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Pédicurie</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="upper">Beauté des Pieds + Soins</td>
              <td className="price">65.-</td>
            </tr>
            <tr>
              <td className="upper">Beauté des Pieds + pose vernis</td>
              <td className="price">75.-</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Pricing>
  )
}

export default pricingtable
